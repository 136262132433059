import Api from '../api';

export const checkLastUpdatedData = async function () {
  try {
    const response = await Api.get('checkLastUpdatedData');
    return response.body;
  } catch (error) {
    console.error('Error fetching last updated data:', error);
    throw error;
  }
};
