import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import Bubble from '../components/bubble/';
// import { HomeIntroDropdown } from '../components/layouts/';
import CountryService from '../services/country/index';
import * as CountryAction from '../actions/country/index';
import {
  Container,
  Heading,
  Text,
  HomeHeroButton
  // DownloadImageButton
} from '../components/styled/ui/';
import { rgba, flex, media } from '../components/styled/utils/';
import * as projectAction from '../actions/project/index';
import ProjectService from '../services/project/index';
import { getAppImages } from '../helpers';
import { connect } from 'react-redux';
import CountryCompanySelect from '../components/layouts/countryCompanySelect';
import {checkLastUpdatedData} from '../services/Data/data.services';
import moment from 'moment';

// local images
// import CloudUp from '../images/svg/Cloud-up.svg';
// import CloudDown1 from '../images/svg/Cloud-down-1.svg';
// import CloudDown2 from '../images/svg/Cloud-down-2.svg';
// import CloudDown3 from '../images/svg/Cloud-down-3.svg';
// import CloudCenter from '../images/svg/Cloud-center.svg';
// import Globe from '../images/svg/globe.svg';
// import CoinsFront from '../images/svg/coin.svg';
// import CoinsBack from '../images/svg/coins_back.svg';

// import videoThumbnail from '../images/video-thumbnail-new.png';
// import videoRP from '../images/video/resource_projects.mp4';
// import videoPlayerThumbnail from '../images/video/resource_projects.png';

const {
  arrowRightIcon,
  // downloadChartIcon,
  companyProfile,
  countryProfile,
  updateSubscribe,
  RLogo,
  closeIcon,
  CloudUp,
  CloudDown1,
  CloudDown2,
  CloudDown3,
  CloudCenter,
  Globe,
  CoinsFront,
  CoinsBack,
  videoThumbnail,
  videoRP
} = getAppImages;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      component: 'projects',
      opacity: 0,
      newData: {},
      showNotification: true,
      isModalOpen: false,
      // searchValue: ''
      // totalCountries: 0,
      lastUpdated: {updatedAt: null}
    };
    this.myRef = React.createRef();
  }
  componentWillMount() {
    this.props.getBubbleData('project');
    this.props.getOverAllStats();
    this.props.getCountryCompanyList();
    ProjectService.checkNewData().then(r =>
      this.setState({ newData: r.result })
    );
    checkLastUpdatedData()
    .then(r => {
      const result = r.result || [];

      const { uploaded_at } = (result && result.length > 0) ? result[0] : {};
  
      const updatedAt = uploaded_at ? moment(uploaded_at).format('MMMM D, YYYY') : null;
  
      this.setState({
        lastUpdated: {
          updatedAt
        }
      });
    })
    .catch(error => {
      console.error("Error fetching last updated data:", error);
      this.setState({
        lastUpdated: {
          updatedAt: null,
        }
      });
    });

  }
  onIndexChange(val) {
    this.props.getBubbleData(val);
    this.setState({ component: val === 'project' ? 'projects' : 'entities' });
  }
  onMouseEnter(countryIncome) {
    this.props.getCountryIncome(countryIncome);
  }
  onMouseLeave(countryIncome) {
    this.props.getCountryIncome(countryIncome);
  }

  // componentWillReceiveProps(props) {
  //   this.setState({
  //     totalCountries: props.countryList.length
  //   });
  // }

  componentDidMount() {
    window.onscroll = () => {
      const newScrollHeight = Math.ceil(window.scrollY / 50) * 250;
      if (this.state.currentScrollHeight !== newScrollHeight) {
        this.setState({ currentScrollHeight: newScrollHeight });
      }
    };
  }

  renderIntro() {
    return (
      <IntroWrap>
        <div className="heading">
          <Heading.h2 light uppercase>
            Open data on
          </Heading.h2>

          <Heading light uppercase className="hero-title">
            <i>
              {' '}
              oil, gas and <br></br>mining payments{' '}
            </i>

            {/* <HomeIntroDropdown
              onChange={val => this.onIndexChange(val)}
              items={[
                { label: 'per Project', value: 'project' },
                { label: "to Gov't Agencies", value: 'entity' }
              ]}
            /> */}
          </Heading>
        </div>

        <div className="learn-more-text">
          <Text.span light size={16}>
            Explore payments to governments made by companies for extracting
            oil, gas and mineral resources around the globe.
          </Text.span>
        </div>
        <Link to="/projects">
          <HomeHeroButton className="hero-button">
            Project-level Payments
          </HomeHeroButton>
        </Link>
        <Link to="/entities">
          <HomeHeroButton className="hero-button hero-button-secondary">
            Government Revenues
          </HomeHeroButton>
        </Link>
      </IntroWrap>
    );
  }

  renderBubble() {
    return this.props.bubbleData.fetching ? (
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    ) : (
      <Bubble component={this.state.component} />
    );
  }

  renderVideo() {
    const openVideo = () => {
      this.setState({ isModalOpen: !this.state.isModalOpen }, () => {
        if (this.myRef.current) {
          this.myRef.current.focus();
        }
      });
      document.body.classList.toggle('no-scroll');
    };
    const noChange = e => {
      e.stopPropagation();
    };
    return (
      <React.Fragment>
        <VideoWrap onClick={openVideo}>
          <div className="video-thumbnail-img-wrapper">
            <div className="video-thumbnail-img">
              <div className="Cloud-up cloud">
                <img src={CloudUp} alt="cloud"></img>
              </div>
              <div className="Cloud-down-1 cloud">
                <img src={CloudDown1} alt="cloud"></img>
              </div>
              <div className="Cloud-down-2 cloud">
                <img src={CloudDown2} alt="cloud"></img>
              </div>
              <div className="Cloud-down-3">
                <img src={CloudDown3} alt="cloud"></img>
              </div>
              <div className="Cloud-center">
                <img src={CloudCenter} alt="cloud"></img>
              </div>
              <div className="Globe">
                <img src={Globe} alt="Globe"></img>
              </div>
              <div className="Coins-front-1">
                <img src={CoinsFront} alt="Globe"></img>
              </div>
              <div className="Coins-front-2">
                <img src={CoinsFront} alt="Globe"></img>
              </div>
              <div className="Coins-Back">
                <img src={CoinsBack} alt="Globe"></img>
              </div>
            </div>
          </div>
          <div className="watch-video-icon">
            {/* <p>Intro to ResourceProjects.org</p> */}
            <div className="play-button"></div>
            <p>Discover ResourceProjects.org</p>
          </div>
        </VideoWrap>
        {this.state.isModalOpen && (
          <VideoModal onClick={openVideo}>
            <div
              className="video-container"
              onClick={noChange}
              ref={this.myRef}
              tabIndex="0"
              onKeyDown={e => {
                if (e.keyCode === 27) {
                  this.setState({ isModalOpen: !this.state.isModalOpen });
                  document.body.classList.toggle('no-scroll');
                }
              }}
            >
              <div className="video-wrapper">
                <video poster={videoThumbnail} controls autoPlay>
                  <source src={videoRP} type="video/mp4" />
                  Your browser does not support HTML5 video.
                </video>
              </div>
              <div className="close" onClick={openVideo}></div>
            </div>
          </VideoModal>
        )}
      </React.Fragment>
    );
  }

  goTo(path) {
    this.props.history.push(path);
  }

  renderLegend() {
    return (
      <Legend onMouseLeave={() => this.onMouseLeave(null)}>
        <ul>
          <li
            className="legend-list-item"
            onMouseEnter={() => this.onMouseEnter('high-income')}
            // onMouseLeave={() => this.onMouseLeave(null)}
          >
            <Text.span>High-income countries</Text.span>
          </li>
          <li
            className="legend-list-item"
            onMouseEnter={() => this.onMouseEnter('upper-middle-income')}
            // onMouseLeave={() => this.onMouseLeave(null)}
          >
            <Text.span>Upper-middle-income countries</Text.span>
          </li>
          <li
            className="legend-list-item"
            onMouseEnter={() => this.onMouseEnter('lower-middle-income')}
            // onMouseLeave={() => this.onMouseLeave(null)}
          >
            <Text.span>Lower-middle-income countries</Text.span>
          </li>
          <li
            className="legend-list-item"
            onMouseEnter={() => this.onMouseEnter('low-income')}
            // onMouseLeave={() => this.onMouseLeave(null)}
          >
            <Text.span>Low-income countries</Text.span>
          </li>
        </ul>
      </Legend>
    );
  }
  renderCitation() {
    return (
      <Citation>
        <p className="citation_hide">Source:</p>
      </Citation>
    );
  }

  getDatasetSchema() {
    return {
      '@context': 'http://schema.org',
      '@type': 'Dataset',
      name: 'Resource Projects',
      author: {
        '@type': 'Organization',
        name: 'Natural Resource Governance Institute'
      },
      description:
        'Explore payments made by companies for extracting oil, gas and mining resources around the world.',
      url: 'https://resourceprojects.org',
      keywords: [
        'MANDATORY DISCLOSURE > PAYMENTS',
        'PAYMENTS TO GOVERNMENT',
        'PROJECT LEVEL PAYMENTS',
        'EXTRACTIVE SECTOR PAYMENTS',
        'MINING AND OIL GAS PAYMENTS'
      ],
      creator: {
        '@type': 'Organization',
        name: 'Natural Resource Governance Institute',
        contactPoint: {
          '@type': 'ContactPoint',
          contactType: 'public engagement',
          email: 'admin@resourceprojects.org',
          url: 'https://resourcegovernance.org/'
        }
      },
      distribution: [
        {
          '@type': 'DataDownload',
          encodingFormat: 'application/zip',
          contentUrl: 'https://resourceprojects.org'
        }
      ],
      temporalCoverage: '2014-01-01/..',
      spatialCoverage: {
        '@type': 'Place',
        name: 'Worldwide'
      }
    };
  }

  inputChange(e) {
    this.setState({ searchValue: e.target.value })
  }
  handleSelect(selectedOption){
    this.props.history.push(selectedOption.type === 'Country'? `/country/${selectedOption.value}` : `/company/${selectedOption.value}`);
  }

  render() {
    let { countryCount, reportingCompanyNo: companyCount } = this.props.stats || {};
    let { newData, showNotification, searchValue, lastUpdated } = this.state || {};
    const opacity = Math.min(100 / this.state.currentScrollHeight || 1, 1);
    const transitionStyles = {
      entered: {
        opacity: 1,
        visibility: 'visible',
        transform: 'translateY(0)'
      }
    };
    const { countryCompanyList } = this.props;
    const filteredList = [...countryCompanyList].map(d => {
      return { value: d.type === 'Country' ? d.code : d.name , label: d.name ,type: d.type};
    });
    return (
      <div>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(this.getDatasetSchema())}
          </script>
        </Helmet>
        <Container>
          <Grid className="homepage__top">
            <div className="column">{this.renderIntro()}
            <div className="select-country">
            <CountryCompanySelect />
                {/* <Select
                  onChange={selectedOption =>
                    this.handleSelect(selectedOption)
                  }
                  options={
                    filteredList
                  }
                  placeholder="Search for countries and companies"
                /> */}
            </div>
            </div>
            <div className="column video-thumbnail">{this.renderVideo()}</div>
            <div>
            </div>
            <ScrollBlock style={{ opacity }}>
              <div className="mouse" />
              <p>Scroll</p>
            </ScrollBlock>
          </Grid>
          <ProfileWrap>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => this.goTo('/company-profiles')}
              className="column"
            >
              <img src={companyProfile} alt="" />
              <div className="column__content">
                <Heading.h2>Company Profiles</Heading.h2>
                <p>
                  Access {companyCount || 0} company profiles to understand
                  their extractive sector activities around the world.
                </p>
                <span className="more" title="Read More">
                  More
                </span>
              </div>
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => this.goTo('/country-profiles')}
              className="column"
            >
              <img src={countryProfile} alt="" />
              <div className="column__content">
                <Heading.h2>Country Profiles</Heading.h2>
                <p>
                  Browse through {countryCount || 0} country profiles to get the
                  full picture on publicly-reported natural resource payments to
                  governments.
                </p>
                <span className="more" title="Read More">
                  More
                </span>
              </div>
            </div>
            <div
              onClick={() => this.goTo('/subscribe')}
              style={{ cursor: 'pointer' }}
              className="column"
            >
              <img src={updateSubscribe} alt="" />
              <div className="column__content">
                <Heading.h2>Setup an alert</Heading.h2>
                <p>
                  Tell us what you are interested in and we will let you know
                  when we add relevant new data.
                </p>
                <button type="submit" className="btn-subscribe">
                  Setup
                </button>
              </div>
            </div>
          </ProfileWrap>
        </Container>
        <FooterOverlay />
        {lastUpdated.updatedAt && (
            <DataUpdateAlert>
              <img
                src={closeIcon}
                alt="Resource Projects"
                width="8"
                height="8"
                className="close"
                onClick={() => this.setState({ lastUpdated: {updatedAt: null} })}
              />
              <div>
                <img
                  src={RLogo}
                  alt="Resource Projects"
                  width="21"
                  height="24"
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: `<p>The data on this site was last updated on <strong>${lastUpdated.updatedAt}</strong></p>`
                  }}
                />
              </div>
            </DataUpdateAlert>
          )}

        {showNotification &&
          newData.newReportCount > 0 && (
            <DataAlert>
              <img
                src={closeIcon}
                alt="Resource Projects"
                width="8"
                height="8"
                className="close"
                onClick={() => this.setState({ showNotification: false })}
              />
              <div>
                <img
                  src={RLogo}
                  alt="Resource Projects"
                  width="21"
                  height="24"
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: `<a href='/sources/newReports'><strong>${newData.newReportCount}</strong> new reports</a> with payments totaling <strong>$${newData.payment}</strong> were added over last<strong> 7 days.</strong>`
                  }}
                />
              </div>
            </DataAlert>
          )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getBubbleData: component => {
    dispatch(projectAction.bubbleDataFetching());
    ProjectService.bubbleData(component)
      .then(response => {
        dispatch(projectAction.bubbleDataFetched(response));
      })
      .catch(error => {
        dispatch(projectAction.bubbleDataError(error));
      });
  },
  getOverAllStats: () => {
    CountryService.getOverAllStats().then(response => {
      dispatch(CountryAction.overallStatsFetched(response.body.result));
    });
  },
  getCountryIncome: countryIncome => {
    dispatch(projectAction.countryIncome(countryIncome));
  },
  getCountryCompanyList: ()=>{
    CountryService.getCountryCompanyList().then(response => {
      dispatch(CountryAction.countryCompanyList(response.body.data));
    });
  }
});

const mapStateToProps = state => {
  return {
    bubbleData: state.project,
    stats: state.country.overallStats,
    countryCompanyList: state.country.countryCompanyList
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

const IntroWrap = styled.div`
  max-width: 510px;
  position: relative;
  z-index: 0;

  @media (max-width: 991px) {
    margin: 0 auto;
    text-align: center;
  }

  .heading {
    margin-bottom: 18px;
    @media (max-width: 991px) {
      margin-bottom: 8px;
    }

    h2 {
      color: ${rgba('#fff', 0.7)};
      font-weight: ${props => props.theme.fontWeight.base};
      line-height: 1.92;
      font-size: 22px;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 32px;
      line-height: 1.19;
      color: ${props => props.theme.primary.white};
      font-weight: ${props => props.theme.fontWeight.base};
      &.hero-title {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        line-height: 1.4;
        @media (max-width: 991px) {
          justify-content: center;
        }
        > span {
          margin: 6px 6px 6px 0;
        }
        .dropdown-title {
          padding: 2px 12px 4px;
          h4 {
            font-size: 24px;
          }
        }
        br {
          @media (max-width: 991px) {
            display: none;
          }
        }
      }

      ${media.md`
        font-size: 46px;             
        &.hero-title {
          > span {
          margin-top: 3px;
          }
          .dropdown-title h4 {
            font-size: 36px;
          }
        }
      `};

      i {
        margin-right: 6px;
      }
    }
  }

  .learn-more-text {
    margin-bottom: 20px;
    padding-right: 40px;

    @media (max-width: 991px) {
      padding: 0;
    }

    > span {
      line-height: 1.63;
      margin-right: 6px;
    }

    > a {
      color: ${props => props.theme.primary.primary};
      font-weight: ${props => props.theme.fontWeight.bold};

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .hero-button {
    font-size: 16px;
    padding: 16px 20px;
    font-weight: 700;
    border-radius: 6px;
    margin-right: 20px;
    margin-bottom: 15px;
    position: relative;
    box-shadow: 0 2px 40px 0 rgba(0,0,0,0.5);
    @media (max-width: 500px) {
      display: block;
      margin: 0 auto 15px;
    }

    /* &:after {
      content: '';
      width: 90%;
      position: absolute;
      height: 20px;
      background: #152932;
      display: block;
      z-index: -1;
      filter: blur(20px);
      bottom: -5px;
      left: 0;
      right: 0;
      margin: auto;
      transition: all 0.2s ease-in;
    } */

    &:hover {
      box-shadow: 0 15px 60px 0 rgba(0,0,0,0.5);
      /* &:after {
        filter: blur(30px);
      } */
    }
    a {
      color: #fff;
    }

    &-secondary {
      background: linear-gradient(to bottom, #f4d849, #dec232);
      color: #3a424c;
      @media (max-width: 991px) {
        margin-right: auto;
      }

      &:hover {
        background: linear-gradient(to bottom, #f4d849, #cdac05);
      }
    }
    ${media.md`
      margin-bottom: 0;
    `};
  }
`;

const VideoWrap = styled.div`
  border-radius: 12px;
  /* overflow: hidden; */
  position: relative;
  height: 408px;
  z-index: 0;
  cursor: pointer;
  box-shadow: 0 2px 40px 0 rgba(0,0,0,0.5);
  transition: box-shadow 0.3s ease-in-out;

  @media (max-width: 991px){
    max-width: 520px;
    margin: 0 auto;
  }

  /* &:after{
    content: "";
    width: 88%;
    position: absolute;
    height: 90%;
    background: rgba(39, 32, 44, 0.9);
    display: block;
    z-index: -1;
    filter: blur(20px);
    bottom: -5px;
    left: 0;
    right: 0;
    margin: auto;
    /* transition: all 0.2s ease-in;
  } */

  .video-thumbnail-img-wrapper{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out;
    -webkit-perspective: 1000;

    .video-thumbnail-img{
      width: 100%;
      height: 100%;
      /* background: #39414c url(${videoThumbnail}) no-repeat; */
      /* background: #fff url(${videoThumbnail}) no-repeat; */
      background-size: cover;
      /* background-position: center; */
      /* transform: scale(1) translateY(0); */
      transition: all .2s ease-in-out;
      background-color:#E1ECFE;
      position:relative;
      .Cloud-up{
        position:absolute;
        left: -18%;
        top:-2%;
        width:400px;
        height:160px;
        z-index:1;
        transition:all .4s ease;
      }
      .Cloud-down-1{
        position: absolute;
        left: -10px;
        bottom: -12px;
        height:118px;
        width:228px;
        transition:all .4s ease;
      }
      .Cloud-down-2{
        position: absolute;
        left: 45%;
        bottom: 0;
        width:262.1px;
        height:72.06px;
        z-index: 3;
        transition:all .4s ease;
      }
      .Cloud-down-3{
        position:absolute;
        right: -5%;
        bottom: 0;
        width :268;
        height:72;
        z-index:2;
        transition:all .4s ease;
      }
      .Cloud-center{
        position:absolute;
        left:42%;
        top:63%;
        height:45px;
        width:140px;
        z-index:2;
        transition:all .4s ease;
      }
      .Globe{
        position:absolute;
        right:-21%;
        top:8%;
        bottom:0;
        z-index:1;
        transition:all .4s ease;
      }
      .Coins-front-1{
        position:absolute;
        top:32.5%;
        left:58%;
        z-index:2;
        transition:all .4s ease;
      }
      .Coins-front-2{
        position:absolute;
        top:76%;
        left:54%;
        z-index:0;
        transition:all .4s ease;
        transform:rotate(-60deg);
      }
      .Coins-Back{
        position:absolute;
        right: 2%;
        z-index:0;
        transition:all .4s ease;
      }
      @media (max-width: 991px){
        background-position: center;
      }

    }
  }

  img{
    width: 100%;
  }

  .watch-video-icon{
    position: absolute;
    top: 54%;
    left: 30%;
    transform: translate(-50%, -50%);

    .play-button{
      width: 67px;
      height: 67px;
      margin: 0 auto;
      border-radius: 50%;
      background: linear-gradient(to bottom,#43dffd,#20bcfb);
      position: relative;
      transition: all 0.2s ease-in;
      box-shadow: 0 2px 10px rgba(18, 41, 46, 0.1);

      &:after {
          content: "";
          border-bottom: 10px solid transparent;
          border-top: 10px solid transparent;
          border-left: 15px solid #fff;
          display: block;
          position: absolute;
          top: 50%;
          left: 51%;
          transform: translate(-50%, -50%);
      }
    }

    p{
      font-size: 10px;
      color: #3A424C;
      text-transform: uppercase;
      font-weight: 700;
      opacity: 50%;
      margin-top: 8px;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  &:hover{

    transition: all 0.4s ease-in;
    box-shadow: 0 25px 80px 0 rgba(0,0,0,0.5);
    &:after{
        filter: blur(40px);
        background: #080809;
        height: 90%;
        width: 90%;
    }

    .play-button{
      box-shadow: 0 2px 10px rgba(18, 41, 46, 0.4);
    }

    .video-thumbnail-img{
      /* transform: scale(1.1) translateY(-15px); */
      /* transform: scale(1.15) translateY(-25px); */
      .Cloud-up{
         transform: scale(1.1) translateX(26px);
      }
      .Cloud-down-1{
        transform: translateX(10px);
      }
      .Cloud-down-2{
        transform: translateX(-10px);
      }
      .Cloud-down-3{
      }
      .Cloud-center{
        transform: translateX(-20px);
      }
      .Globe{
        transform: scale(1.1) translateX(-10px);
      }
      .Coins-front-1{
        
      }
      .Coins-front-2{
        
      }
      .Coins-Back{
        transform: translateY(-4px);
      }
    }
  }
`;

const VideoModal = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;

  .video-container {
    position: relative;
    width: 80%;
    height: 80%;
    max-width: 880px;
    max-height: 495px;
    outline: none;
    /* @media (max-width: 991px){
        height: auto;
      } */

    .video-wrapper {
      background: #fff;
      border-radius: 12px;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }

    video {
      width: 100%;
      height: 100%;
      border-radius: 12px;
    }
  }

  .close {
    position: absolute;
    right: -32px;
    top: -32px;
    width: 32px;
    height: 32px;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }

    &::before,
    &::after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: #fff;
    }

    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
`;

const Grid = styled.div`
  padding-top: 20px;
  ${media.sm`
    padding: 60px 0;
  `};

  ${media.md`
    ${flex('row', null, 'center')};
    padding: 0 0 100px;
  `};

  &.homepage__top {
    ${media.md`height: calc(100vh - 110px);position: relative;`}
    > .column {
      ${media.md`width: 55%;`}
      + .column {
        ${media.md`width: 50%;`}
      }
    }
    @media all and (min-width: 992px) and (min-height: 650px) {
      height: 78vh;
    }
    .column--chart:hover .download-image {
      background: #978aa8;
      padding: 0 8px 0 4px;
      transition: width 0.3s ease-in-out;
      width: 132px;
      span {
        line-height: 24px;
        display: block;
      }
    }

    .video-thumbnail {
      margin: 60px 0;

      ${media.md`
        margin: 0;
      `};
    }

    .select-country{
      padding: 24px 0px;
      max-width: 464px;
      @media all and (max-width: 992px) {
        margin: 0 auto;
      }
      .css-bg1rzq-control,.css-1szy77t-control {
        background: rgba(255, 255, 255, 0.1);
        border: 2px solid #fff;
        border-radius: 6px;
        box-shadow: none;
        color: #fff;
        font-size: 16px;
        padding: 10px 25px;
        &:before {
            background: url(../images/ic_search.svg) no-repeat 0 0;
            content: "";
            filter: brightness(0) invert(1);
            height: 20px;
            margin-top: 3px;
            width: 20px;
        }
      }
      .css-151xaom-placeholder {
        color: #fff;
      }
      .css-1wy0on6 {
        display: none;
      }
      .css-1g6gooi {
        color: #fff;
      }
      .label-wrap {
       display: flex;
      }
      .css-kj6f9i-menu {
        font-size: 15px;
        z-index: 10;
        img {
          box-shadow: rgb(0 0 0 / 25%) 0px 1px 2px;
          margin-right: 12px;
          vertical-align: middle;
        }
      }
      .css-5ih5ya-group {
        color: #000;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 6px;
      }
      .css-kj6f9i-menu {
        padding: 12px;
      }
      .name-wrap {
        position: relative;
        > div {
          height: 38px;
          margin-bottom: 2px;
        }
        &:hover .hover-content {
            height: initial;
            display: block;
        }
      }
      #react-select-3-group-0-heading + div .label-wrap > span, #react-select-3-group-1-heading + div .label-wrap > span {
        display: block;
        line-height: normal;
      }
    }
  }
`;

const Legend = styled.div`
  /* padding: 0 40px; */
  position: relative;
  margin-bottom: 40px;
  ${media.sm`margin-bottom: 0`}
  /* @media all and (min-width: 600px) and (max-width: 767px) {
    display: flex;
    align-items: baseline;
    > span {
      display: block;
    }
  }
  @media all and (max-height: 650px) {
    display: flex;
    align-items: baseline;
    > span {
      display: block;
    }
  } */

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    ${media.sm`
      ${flex('row', null, 'flex-start')};
      flex-wrap: nowrap;
  `};

    @media all and (min-width: 400px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    @media all and (min-width: 520px) and (min-height: 650px) {
      margin-left: 7%;
      width: 500px;
    }
    ${media.md`
      margin: 0;
      padding: 0;
      width: 440px;
      margin-left: 60px;
    `};
  }

  .legend-list-item {
    color: ${rgba('#eceaf1', 0.5)};
    clear: both;
    margin-bottom: 4px;
    line-height: normal;
    width: 45%;
    @media all and (min-width: 400px) {
      padding-right: 8px;
    }
    span {
      color: ${rgba('#eceaf1', 0.5)};
      cursor: pointer;
      font-size: 12px;
      transition: color 0.3s ease-in-out;
      &:hover {
        color: ${rgba('#eceaf1', 0.8)};
      }
    }

    &:before {
      content: '';
      border-radius: 6px;
      float: left;
      margin-right: 4px;
      height: 6px;
      width: 6px;
      margin-bottom: 10px;
      margin-top: 8px;
      @media all and (max-width: 577px) {
        margin-top: 6px;
      }
    }

    &:nth-child(1) {
      &:before {
        background-color: #ff8d8d;
      }
    }

    &:nth-child(2) {
      &:before {
        background-color: #b99898;
      }
    }

    &:nth-child(3) {
      &:before {
        background-color: #7dd3d0;
      }
    }

    &:nth-child(4) {
      &:before {
        background-color: #a0b7c2;
      }
    }
  }
  & + span {
    ${media.md`
      /* padding-left: 2%; */
    `};
  }
`;
const Citation = styled.div`
  .citation_hide: {
    display: none;
  }
`;

const ProfileWrap = styled.div`
  color: ${props => props.theme.primary.white};
  clear: both;
  ${media.sm`display: flex; justify-content: space-between;position: relative; z-index: 1`}
  > .column {
    border-radius: 4px;
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.5);
    height: 455px;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
    transition: box-shadow 0.3s ease-in-out;
    ${media.sm`width: 30%; margin: 0; max-width: 301px;`}
    &:before {
      background: #3a424c;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    a {
      display: block;
      height: 100%;
    }
    img {
      border-radius: 4px;
      height: 100%;
      object-fit: cover;
      opacity: 0.2;
      transition: all 0.7s ease-in-out;
      vertical-align: middle;
      width: 100%;
      /* ${media.sm`width:initial`}; */
    }
    &:nth-child(2) img {
      opacity: 0.4;
    }
    &:last-child {
      img {
        opacity: 0.3;
      }
      p {
        margin-bottom: 14px;
        min-height: initial;
      }
    }
    &:hover {
      box-shadow: 0 2px 80px 0 rgba(0, 0, 0, 0.5);
      img {
        transform: scale(1.2);
      }
    }
  }
  h2 {
    border-bottom: 5px solid ${props => props.theme.primary.mustard};
    color: ${props => props.theme.primary.white};
    font-size: 32px;
    font-weight: 400;
    line-height: 43px;
    margin-bottom: 26px;
    padding-bottom: 8px;
    text-transform: uppercase;
  }
  p {
    color: ${props => props.theme.primary.white};
    line-height: 20px;
    margin-bottom: 20px;
    min-height: 79px;
  }
  .column__content {
    bottom: 10px;
    /* height: 245px; */
    left: 0;
    padding: 28px;
    position: absolute;
    min-height: 66%;
  }
  .more {
    background: url(${arrowRightIcon}) no-repeat 0 0;
    display: block;
    height: 20px;
    text-indent: -9999px;
    transition: all 0.3s ease-in-out;
    width: 30px;
    &:hover {
      transform: translateX(10%);
    }
  }
`;

const ScrollBlock = styled.div`
  bottom: 5%;
  display: none;
  left: 50%;
  position: fixed;
  text-align: center;
  transform: translateX(-50%);
  width: 30px;
  ${media.md`display: block`};
  p {
    margin-top: 10px;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.5);
  }

  @media all and (min-width: 992px) and (min-height: 650px) {
    bottom: 9%;
  }

  @media all and (min-width: 992px) and (min-height: 850px) {
    bottom: 14%;
  }

  .mouse {
    position: relative;
    width: 16px;
    height: 26px;
    border: 1px solid #ddd;
    border-radius: 100px;
    background-size: 100% 200%;
    margin: 0 auto;
  }
  .mouse:before,
  .mouse:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  .mouse:before {
    width: 14px;
    height: 24px;
    /* background-color: #5d55a7; */
    border-radius: 100px;
  }
  .mouse:after {
    background-color: #ffffff;
    width: 2px;
    height: 4px;
    border-radius: 1px;
    -webkit-animation: trackBallSlide 5s linear infinite;
    animation: trackBallSlide 5s linear infinite;
  }

  @-webkit-keyframes colorSlide {
    0% {
      background-position: 0% 100%;
    }
    20% {
      background-position: 0% 0%;
    }
    21% {
      background-color: #776ec3;
    }
    29.99% {
      background-color: #ffffff;
      background-position: 0% 0%;
    }
    30% {
      background-color: #776ec3;
      background-position: 0% 100%;
    }
    50% {
      background-position: 0% 0%;
    }
    51% {
      background-color: #776ec3;
    }
    59% {
      background-color: #ffffff;
      background-position: 0% 0%;
    }
    60% {
      background-color: #776ec3;
      background-position: 0% 100%;
    }
    80% {
      background-position: 0% 0%;
    }
    81% {
      background-color: #776ec3;
    }
    90%,
    100% {
      background-color: #ffffff;
    }
  }

  @keyframes colorSlide {
    0% {
      background-position: 0% 100%;
    }
    20% {
      background-position: 0% 0%;
    }
    21% {
      background-color: #776ec3;
    }
    29.99% {
      background-color: #ffffff;
      background-position: 0% 0%;
    }
    30% {
      background-color: #776ec3;
      background-position: 0% 100%;
    }
    50% {
      background-position: 0% 0%;
    }
    51% {
      background-color: #776ec3;
    }
    59% {
      background-color: #ffffff;
      background-position: 0% 0%;
    }
    60% {
      background-color: #776ec3;
      background-position: 0% 100%;
    }
    80% {
      background-position: 0% 0%;
    }
    81% {
      background-color: #776ec3;
    }
    90%,
    100% {
      background-color: #ffffff;
    }
  }
  @-webkit-keyframes trackBallSlide {
    0% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(-6px);
      transform: scale(1) translateY(-6px);
    }
    6% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateY(5px);
      transform: scale(0.9) translateY(5px);
    }
    14% {
      opacity: 0;
      -webkit-transform: scale(0.4) translateY(12px);
      transform: scale(0.4) translateY(12px);
    }
    15%,
    19% {
      opacity: 0;
      -webkit-transform: scale(0.4) translateY(-6px);
      transform: scale(0.4) translateY(-6px);
    }
    28%,
    29.99% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(-6px);
      transform: scale(1) translateY(-6px);
    }
    30% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(-6px);
      transform: scale(1) translateY(-6px);
    }
    36% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateY(5px);
      transform: scale(0.9) translateY(5px);
    }
    44% {
      opacity: 0;
      -webkit-transform: scale(0.4) translateY(12px);
      transform: scale(0.4) translateY(12px);
    }
    45%,
    49% {
      opacity: 0;
      -webkit-transform: scale(0.4) translateY(-6px);
      transform: scale(0.4) translateY(-6px);
    }
    58%,
    59.99% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(-6px);
      transform: scale(1) translateY(-6px);
    }
    60% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(-6px);
      transform: scale(1) translateY(-6px);
    }
    66% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateY(5px);
      transform: scale(0.9) translateY(5px);
    }
    74% {
      opacity: 0;
      -webkit-transform: scale(0.4) translateY(12px);
      transform: scale(0.4) translateY(12px);
    }
    75%,
    79% {
      opacity: 0;
      -webkit-transform: scale(0.4) translateY(-6px);
      transform: scale(0.4) translateY(-6px);
    }
    88%,
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(-6px);
      transform: scale(1) translateY(-6px);
    }
  }
  @keyframes trackBallSlide {
    0% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(-6px);
      transform: scale(1) translateY(-6px);
    }
    6% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateY(5px);
      transform: scale(0.9) translateY(5px);
    }
    14% {
      opacity: 0;
      -webkit-transform: scale(0.4) translateY(12px);
      transform: scale(0.4) translateY(12px);
    }
    15%,
    19% {
      opacity: 0;
      -webkit-transform: scale(0.4) translateY(-6px);
      transform: scale(0.4) translateY(-6px);
    }
    28%,
    29.99% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(-6px);
      transform: scale(1) translateY(-6px);
    }
    30% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(-6px);
      transform: scale(1) translateY(-6px);
    }
    36% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateY(5px);
      transform: scale(0.9) translateY(5px);
    }
    44% {
      opacity: 0;
      -webkit-transform: scale(0.4) translateY(12px);
      transform: scale(0.4) translateY(12px);
    }
    45%,
    49% {
      opacity: 0;
      -webkit-transform: scale(0.4) translateY(-6px);
      transform: scale(0.4) translateY(-6px);
    }
    58%,
    59.99% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(-6px);
      transform: scale(1) translateY(-6px);
    }
    60% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(-6px);
      transform: scale(1) translateY(-6px);
    }
    66% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateY(5px);
      transform: scale(0.9) translateY(5px);
    }
    74% {
      opacity: 0;
      -webkit-transform: scale(0.4) translateY(12px);
      transform: scale(0.4) translateY(12px);
    }
    75%,
    79% {
      opacity: 0;
      -webkit-transform: scale(0.4) translateY(-6px);
      transform: scale(0.4) translateY(-6px);
    }
    88%,
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(-6px);
      transform: scale(1) translateY(-6px);
    }
  }
  @-webkit-keyframes nudgeMouse {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    20% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px);
    }
    30% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px);
    }
    60% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    80% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px);
    }
    90% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes nudgeMouse {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    20% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px);
    }
    30% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px);
    }
    60% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    80% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px);
    }
    90% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @-webkit-keyframes nudgeText {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    20% {
      -webkit-transform: translateY(2px);
      transform: translateY(2px);
    }
    30% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(2px);
      transform: translateY(2px);
    }
    60% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    80% {
      -webkit-transform: translateY(2px);
      transform: translateY(2px);
    }
    90% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes nudgeText {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    20% {
      -webkit-transform: translateY(2px);
      transform: translateY(2px);
    }
    30% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(2px);
      transform: translateY(2px);
    }
    60% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    80% {
      -webkit-transform: translateY(2px);
      transform: translateY(2px);
    }
    90% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @-webkit-keyframes colorText {
    21% {
      color: #776ec3;
    }
    30% {
      color: #ffffff;
    }
    51% {
      color: #776ec3;
    }
    60% {
      color: #ffffff;
    }
    81% {
      color: #776ec3;
    }
    90% {
      color: #ffffff;
    }
  }
  @keyframes colorText {
    21% {
      color: #776ec3;
    }
    30% {
      color: #ffffff;
    }
    51% {
      color: #776ec3;
    }
    60% {
      color: #ffffff;
    }
    81% {
      color: #776ec3;
    }
    90% {
      color: #ffffff;
    }
  }
`;

const FooterOverlay = styled.div`
  background-color: #3a424c;
  ${media.sm`height: 270px;margin-top: -200px;position: relative`}
`;

const DataAlert = styled.div`
  background-image: linear-gradient(180deg, #ffffff 0%, #f2f2f2 100%);
  border: 1px solid #ffffff;
  border-radius: 4px;
  bottom: 24px;
  left: 42px;
  padding: 16px 22px;
  position: fixed;
  min-width: 300px;
  max-width: 400px;
  z-index: 1;
  > div {
    align-items: center;
    display: flex;
  }
  p {
    color: ${props => props.theme.primary.dark};
    font-size: 12px;
    line-height: 17px;
    margin-left: 18px;
  }
  a {
    color: ${props => props.theme.primary.primary};

    &:hover {
      text-decoration: underline;
    }
  }
  strong {
    font-weight: bold;
  }
  .close {
    left: 6px;
    position: absolute;
    top: 6px;
    cursor: pointer;
  }
`;

const DataUpdateAlert = styled.div`
  background-image: linear-gradient(180deg, #ffffff 0%, #f2f2f2 100%);
  border: 1px solid #ffffff;
  border-radius: 4px;
  top: 0px;
  left: 42px;
  padding: 16px 22px;
  position: fixed;
  min-width: 300px;
  max-width: 400px;
  z-index: 1;
  > div {
    align-items: center;
    display: flex;
  }
  p {
    color: ${props => props.theme.primary.dark};
    font-size: 12px;
    line-height: 17px;
    margin-left: 18px;
  }
  a {
    color: ${props => props.theme.primary.primary};

    &:hover {
      text-decoration: underline;
    }
  }
  strong {
    font-weight: bold;
  }
  .close {
    left: 6px;
    position: absolute;
    top: 6px;
    cursor: pointer;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  font-size: 12px;
  background: ${props => props.theme.sec.lightGreen};
  border: 1px solid #d8e1e6;
  border-radius: 18px;
  transition: all 0.15s ease-in-out;

  &:focus {
    outline: none;
    border-color: rgba(9, 215, 203, 0.5);
  }
`;
